import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import queryString from 'query-string';
import omit from 'lodash/omit';

import { formatServerError, parseServerError } from 'helpers';
import { getSelectedSite } from 'redux/auth/selectors';
import { ERROR_DIALOG_OPENED, openErrorDialog } from 'redux/errorHandler';

import { getReportsListSelector } from './selectors';
import { normalizeByUnitName, formatTriggers, changeAssigneeName } from './formatters';
import http from '../../http';
import { getTasks } from 'http/tasks';
import {
  getAttentionTasks,
  getSiteStatistics,
  getTriggerQueue,
  queueCommitUnit,
  getReportsList,
  postReport,
  putReport,
  removeReport,
  getReportPreview,
  getReport,
  generateAndDownloadReport,
  downloadFile,
  getListFileReports,
  deleteFile,
} from 'http/queue';

import { actions } from './index';

let payloadForUpdateList;

function* fetchQueueList({ payload: { rangeStart } }) {
  try {
    const url = 'Queue/List';
    const start = moment(rangeStart).format('MM/DD/YYYY');
    const yesterday = moment().subtract(1, 'day').format('MM/DD/YYYY');
    const weekAfterStart = moment(rangeStart).add(6, 'day').format('MM/DD/YYYY');
    const paramsDay = queryString.stringify({
      'filter.ScheduledDateRangeStart': start,
      'filter.ScheduledDateRangeEnd': start,
    });
    const paramsWeek = queryString.stringify({
      'filter.ScheduledDateRangeStart': start,
      'filter.ScheduledDateRangeEnd': weekAfterStart,
    });
    const paramsPast = queryString.stringify({
      'filter.ScheduledDateRangeEnd': yesterday,
    });
    const paramsRequireAttention = queryString.stringify({
      'filter.ScheduledDateRangeStart': start,
      'filter.OnlyRequireAttention': true,
    });
    const paramsRequireAttentionTaskParts = queryString.stringify({
      'filter.ScheduledDateRangeStart': start,
      'filter.OnlyRequireAttention': true,
      'filter.OnlyTaskPartsRequireAttention': true,
    });
    const [responseDay, responseWeek, responsePast, responseRequireAttention, responseRequiredAttetionTaskParts] =
      yield all([
        call(http.post, url, paramsDay),
        call(http.post, url, paramsWeek),
        call(http.post, url, paramsPast),
        call(http.post, url, paramsRequireAttention),
        call(http.post, url, paramsRequireAttentionTaskParts),
      ]);

    const queueList = {
      today: normalizeByUnitName(changeAssigneeName(responseDay.data.root)),
      days: normalizeByUnitName(changeAssigneeName(responseWeek.data.root)),
      past: normalizeByUnitName(changeAssigneeName(responsePast.data.root)),
      all: normalizeByUnitName(changeAssigneeName(responseRequireAttention.data.root)),
      parts: normalizeByUnitName(changeAssigneeName(responseRequiredAttetionTaskParts.data.root)),
    };

    yield put(actions.fetchQueueListSuccess(queueList));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: error });
    yield put(actions.fetchQueueListFailure(error));
  }
}

function* commitUnit({ payload: { rangeEnd, rangeStart, unitId } }) {
  try {
    const params = `filter.ScheduledDateRangeStart=${rangeStart}
    &filter.ScheduledDateRangeEnd=${rangeEnd}&unitId=${unitId}`;
    const { data } = yield call(queueCommitUnit, params);
    if (data.entity && data.entity.Success) {
      yield put(actions.commitUnitSuccess(data.entity));
      yield put(actions.commitUnitSuccess(''));
      yield* fetchQueueList({ payload: { rangeStart: moment(rangeStart, 'MM/DD/YYYY').format('YYYY-MM-DD') } });
    } else {
      yield put(
        actions.commitUnitFailure({ message: data.entity ? data.entity.Message : 'Failed to commit the Unit' }),
      );
    }
  } catch (error) {
    yield put(actions.commitUnitFailure(parseServerError(error)));
  }
}

function* fetchAssignedTasks(action) {
  try {
    const {
      period,
      rangeStart,
      unitId,
      userId,
      isUnassigned,
      includeAssigned,
      includeCompleted,
      includeTeam,
      isTeamOnly,
      requiresAttention,
      requireOnlyCompleted,
      requiredAttetionParts,
    } = action.payload;

    const defaultParams = {
      ByUser: userId,
      ByUnit: unitId,
      IsUnassigned: isUnassigned,
      IncludeInactive: true,
      IncludeAssigned: includeAssigned,
      IncludeCompleted: includeCompleted || false,
      IncludeTeam: includeTeam || false,
      IsTeamOnly: isTeamOnly || false,
      RequiresAttention: requiresAttention,
      RequireOnlyCompleted: requireOnlyCompleted,
      RequiresAttentionParts: requiredAttetionParts,
    };

    const start = moment(rangeStart).format('MM/DD/YYYY');
    const yesterday = moment().subtract(1, 'day').format('MM/DD/YYYY');
    const weekAfterStart = moment(rangeStart).add(1, 'week').format('MM/DD/YYYY');

    const paramsMap = {
      today: {
        ...defaultParams,
        ScheduledDateRangeStart: start,
        ScheduledDateRangeEnd: start,
      },
      days: {
        ...defaultParams,
        ScheduledDateRangeStart: start,
        ScheduledDateRangeEnd: weekAfterStart,
      },
      past: {
        ...defaultParams,
        ScheduledDateRangeEnd: yesterday,
      },
      all: {
        ...defaultParams,
      },
      parts: {
        ...defaultParams,
      },
    };
    const filter = paramsMap[period];
    const { data } = yield call(getTasks, { filter });
    const toggleType = yield select(state => state.queue.toggleType);

    if (toggleType === 'quicktask') {
      yield put(actions.fetchAssignedTasksSuccess(data.root.filter(({ TaskType }) => TaskType === 'QuickTask')));
    } else {
      yield put(actions.fetchAssignedTasksSuccess(data.root));
    }
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error, 'Alert')));
    yield put(actions.fetchAssignedTasksFailure(error));
  }
}

function* fetchTriggerQueue({ payload: { rangeStart } }) {
  try {
    const start = moment(rangeStart).format('MM/DD/YYYY');
    const currentSite = yield select(getSelectedSite);
    const params = queryString.stringify({
      'filter.DateRangeStart': start,
      'filter.DateRangeEnd': start,
      'filter.SiteId': currentSite,
    });
    const { data } = yield call(getTriggerQueue, params);
    yield put(actions.fetchTriggersQueueSuccess(formatTriggers(data.root)));
  } catch (error) {
    yield put(actions.fetchTriggersQueueFailure(error));
    yield put(openErrorDialog(formatServerError(error, 'Alert')));
  }
}

function* fetchAttentionTasks() {
  try {
    const date = moment().format('MM/DD/YYYY');
    const { data } = yield call(getAttentionTasks, date);
    yield put(actions.fetchAttentionTasksSuccess(data.entity));
  } catch (error) {
    yield put(actions.fetchAttentionTasksFailure(error));
    yield put(openErrorDialog(formatServerError(error, 'Alert')));
  }
}

function* fetchSiteStatistics({ payload }) {
  const date = moment().format('MM/DD/YYYY');
  const filter = { filter: { date } };
  try {
    const { data } = yield call(getSiteStatistics, payload, filter);
    yield put(actions.fetchSiteStatisticsSuccess(omit(data.entity, 'PastDueAllTasksNumber')));
  } catch (error) {
    yield put(actions.fetchSiteStatisticsFailure(error));
    yield put(openErrorDialog(formatServerError(error, 'Alert')));
  }
}

function* fetchReportsList() {
  try {
    const { data } = yield call(getReportsList);
    yield put(actions.fetchReportsListSuccess(data.root));
  } catch (error) {
    yield put(actions.fetchReportsListFailure(error));
    yield put(openErrorDialog(formatServerError(error, 'Alert')));
  }
}

function* createReport({ payload }) {
  try {
    const { data } = yield call(postReport, payload);
    yield put(actions.createReportSuccess(data.entity));
    yield put(actions.fetchReportsListRequest());
  } catch (err) {
    yield put(actions.createReportFailure(err.response.data.error));
    yield put(openErrorDialog(formatServerError(err, 'Alert')));
  }
}

function* editReport({ payload }) {
  try {
    const { data } = yield call(putReport, payload);
    yield put(actions.editReportSuccess(data.entity));
    yield put(actions.fetchReportsListRequest());
  } catch (error) {
    yield put(actions.editReportFailure(error));
    yield put(openErrorDialog(formatServerError(error, 'Alert')));
  }
}

function* deleteReport({ payload }) {
  try {
    const { data } = yield call(removeReport, payload);
    yield put(actions.deleteReportSuccess(data.entity));
    yield put(actions.fetchReportsListRequest());
  } catch (error) {
    yield put(actions.deleteReportFailure(error));
    yield put(openErrorDialog(formatServerError(error, 'Alert')));
  }
}

function* fetchReportPreview({ payload }) {
  try {
    let reportsLists = yield select(getReportsListSelector);
    if (!reportsLists.length) {
      const { data } = yield call(getReportsList);
      yield put(actions.fetchReportsListSuccess(data.root));
      reportsLists = yield select(getReportsListSelector);
    }
    const report = reportsLists.find(({ Id }) => Id === payload);
    const { data } = yield call(getReportPreview, report);
    yield put(actions.fetchReportPreviewSuccess(data.root));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: { message: error.response.data.error, title: 'Alert' } });
    yield put(actions.fetchReportPreviewFailure(error));
    yield put(openErrorDialog(formatServerError(error, 'Alert')));
  }
}

function* fetchReport({ payload }) {
  try {
    const { data } = yield call(getReport, payload);
    yield put(actions.fetchReportSuccess(data.entity));
  } catch (error) {
    yield put(actions.fetchReportFailure(error));
    yield put(openErrorDialog(formatServerError(error, 'Alert')));
  }
}

function* generateAndDownloadReportFile({ payload }) {
  try {
    yield call(generateAndDownloadReport, payload);

    yield put(actions.generateAndDownloadReportFileSuccess());
  } catch (error) {
    yield put(actions.generateAndDownloadReportFileFailure(error));
    yield put(openErrorDialog(formatServerError(error, 'Alert')));
  }
}

function* downloadReportFile({ payload }) {
  try {
    const { data } = yield call(downloadFile, payload);
    yield put(actions.downloadReportFileSuccess(data.root));
  } catch (error) {
    yield put(actions.downloadReportFileFailure(error));
    yield put(openErrorDialog(formatServerError(error, 'Alert')));
  }
}

function* fetchListFileReports({ payload }) {
  try {
    payloadForUpdateList = payload;
    const { data } = yield call(getListFileReports, payload);
    yield put(actions.fetchListFileReportsSuccess(data.root));
  } catch (error) {
    yield put(actions.fetchListFileReportsFailure(error));
    yield put(openErrorDialog(formatServerError(error, 'Alert')));
  }
}

function* deleteReportFile({ payload }) {
  try {
    const { data } = yield call(deleteFile, payload);
    yield put(actions.deleteReportFileSuccess(data.root));
    yield put(actions.fetchListFileReportsRequest(payloadForUpdateList));
  } catch (error) {
    yield put(actions.deleteReportFileFailure(error));
    yield put(openErrorDialog(formatServerError(error, 'Alert')));
  }
}

const queueSagas = [
  takeEvery(actions.fetchAssignedTasksRequest, fetchAssignedTasks),
  takeEvery(actions.commitUnitRequest, commitUnit),
  takeEvery(actions.fetchQueueListRequest, fetchQueueList),
  takeEvery(actions.fetchTriggersQueueRequest, fetchTriggerQueue),
  takeEvery(actions.fetchAttentionTasksRequest, fetchAttentionTasks),
  takeEvery(actions.fetchSiteStatisticsRequest, fetchSiteStatistics),
  takeEvery(actions.fetchReportsListRequest, fetchReportsList),
  takeEvery(actions.createReportRequest, createReport),
  takeEvery(actions.editReportRequest, editReport),
  takeEvery(actions.deleteReportRequest, deleteReport),
  takeEvery(actions.fetchReportPreviewRequest, fetchReportPreview),
  takeEvery(actions.fetchReportRequest, fetchReport),
  takeEvery(actions.generateAndDownloadReportFileRequest, generateAndDownloadReportFile),
  takeEvery(actions.downloadReportFileRequest, downloadReportFile),
  takeEvery(actions.fetchListFileReportsRequest, fetchListFileReports),
  takeEvery(actions.deleteReportFileRequest, deleteReportFile),
];

export default queueSagas;
