import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import moment from 'moment';
import theme from 'theme';

import { exportUnit } from 'http/queue';
import { actions as queueActions } from 'redux/queue';
import { ActionButton } from 'components/Dialogs/v1/Base';
import * as S from './styled';

export const SpreadsheetDownloadDialog = NiceModal.create(props => {
  const { period, date, selectedUnit } = props;
  const modal = useModal();
  const dispatch = useDispatch();

  const onSubmit = () => {
    const rangeStart = moment(date).format('MM-DD-YYYY');
    const isRequireAttentionTasks = period === 'all' || period === 'parts';
    const isRequiredAttentionTasksParts = period === 'parts';
    let rangeEnd;

    if (period === 'today') {
      rangeEnd = rangeStart;
    } else if (period === 'days') {
      rangeEnd = moment(date).add(6, 'd').format('MM-DD-YYYY');
    }

    exportUnit({
      rangeEnd,
      rangeStart,
      unitId: selectedUnit.UnitId,
      isRequireAttentionTasks,
      isRequiredAttentionTasksParts,
    });

    dispatch(queueActions.commitUnitRequest());
  };

  return (
    <S.DialogContainer
      actions={[<ActionButton type="button" isNext isGreenLabel label={'DOWNLOAD'} onClick={onSubmit} />]}
      onRequestClose={modal.hide}
      open={modal.visible}
      title={'Spreadsheet Download'}
      titleColor={theme.secondaryGreen}
    >
      <S.PrimaryText>{selectedUnit.UnitName}</S.PrimaryText>
      <S.SecondaryText>
        Selecting “download” will export a <br /> spreadsheet file of all tasks for the current <br /> unit in the
        current time period selection.
      </S.SecondaryText>
    </S.DialogContainer>
  );
});

SpreadsheetDownloadDialog.propTypes = {
  selectedUnit: PropTypes.shape({
    UnitName: PropTypes.string.isRequired,
    UnitId: PropTypes.string.isRequired,
  }).isRequired,
  period: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};
