const initialState = {
  assignedTasks: [],
  error: '',
  loading: false,
  queueList: {
    today: { entities: {}, keys: [], ids: [] },
    days: { entities: {}, keys: [], ids: [] },
    past: { entities: {}, keys: [], ids: [] },
    all: { entities: {}, keys: [], ids: [] },
    parts: { entities: {}, keys: [], ids: [] },
  },
  triggersQueue: {
    triggersByUserId: {},
    userIds: [],
  },
  pdfReport: null,
  reportList: [],
  report: {},
  attentionTasks: null,
  currentSiteStatistics: null,
  reportPreview: [],
  reportFilesList: [],
  commitMessage: '',
  toggleType: 'all',
  selectedTasksInfo: {},
};

export const commitUnitRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const commitUnitSuccess = (state, { payload }) => ({
  ...state,
  commitMessage: payload.Message,
  loading: false,
});
export const commitUnitFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const fetchAssignedTasksRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const fetchAssignedTasksSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  assignedTasks: payload,
});
export const fetchAssignedTasksFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const resetAssignedTasks = state => ({
  ...state,
  assignedTasks: [],
});

export const fetchQueueListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const fetchQueueListSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  queueList: payload,
});
export const fetchQueueListFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const fetchTriggersQueueRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const fetchTriggersQueueSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  triggersQueue: payload,
});
export const fetchTriggersQueueFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const fetchAttentionTasksRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const fetchAttentionTasksSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  attentionTasks: payload,
});
export const fetchAttentionTasksFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const stateErrorClear = state => ({
  ...state,
  error: '',
});

export const fetchSiteStatisticsRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const fetchSiteStatisticsSuccess = (state, { payload }) => ({
  ...state,
  currentSiteStatistics: payload,
  error: '',
  loading: false,
});
export const fetchSiteStatisticsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const fetchReportsListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const fetchReportsListSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  reportList: payload,
});
export const fetchReportsListFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const createReportRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const createReportSuccess = (state, { payload }) => ({
  ...state,
  report: payload,
  error: '',
  loading: false,
});
export const createReportFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});

export const editReportRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const editReportSuccess = (state, { payload }) => ({
  ...state,
  report: payload,
  reportList: {},
  error: '',
  loading: false,
});
export const editReportFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const deleteReportRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const deleteReportSuccess = state => ({
  ...state,
  error: '',
  loading: false,
});
export const deleteReportFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const fetchReportPreviewRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const fetchReportPreviewSuccess = (state, { payload }) => ({
  ...state,
  reportPreview: payload,
  error: '',
  loading: false,
});
export const fetchReportPreviewFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
  reportPreview: [],
});

export const fetchReportRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const fetchReportSuccess = (state, { payload }) => ({
  ...state,
  report: payload,
  error: '',
  loading: false,
});
export const fetchReportFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const generateAndDownloadReportFileRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const generateAndDownloadReportFileSuccess = state => ({
  ...state,
  error: '',
  loading: false,
});
export const generateAndDownloadReportFileFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const downloadReportFileRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const downloadReportFileSuccess = state => ({
  ...state,
  error: '',
  loading: false,
});
export const downloadReportFileFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const fetchListFileReportsRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const fetchListFileReportsSuccess = (state, { payload }) => ({
  ...state,
  reportFilesList: payload,
  error: '',
  loading: false,
});
export const fetchListFileReportsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const deleteReportFileRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const deleteReportFileSuccess = state => ({
  ...state,
  error: '',
  loading: false,
});
export const deleteReportFileFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const setSelectedTasksInfo = (state, { payload }) => ({
  ...state,
  selectedTasksInfo: payload,
});

export const setToggleType = (state, { payload }) => ({ ...state, toggleType: payload });

export default initialState;
