import { TASK_RULE_TYPES } from 'types/task';

export const divideByDate = list => {
  const data = list.reduce(
    (result, item) => {
      const newItem = { ...item };

      newItem.ItemName = newItem.UnitName;
      newItem.UnitName = newItem.ExecutionDate;

      result.amount += 1;

      if (!result.tasks[newItem.UnitName]) {
        result.tasks[newItem.UnitName] = [];
        result.tasks[newItem.UnitName].push(newItem);
      } else {
        result.tasks[newItem.UnitName].push(newItem);
      }

      return result;
    },
    { tasks: {}, amount: 0 },
  );

  Object.keys(data.tasks).forEach(key => {
    data.tasks[key].sort(
      (a, b) => a.ItemSequence - b.ItemSequence || a.TaskOrder - b.TaskOrder || !!a.Exceptions > !!b.Exceptions,
    );
  });

  return data;
};

export const divideByUnitName = list => {
  const data = list.reduce(
    (result, item) => {
      result.amount += 1;

      if (!result.tasks[item.UnitName]) {
        result.tasks[item.UnitName] = [];
        result.tasks[item.UnitName].push(item);
      } else {
        result.tasks[item.UnitName].push(item);
      }

      return result;
    },
    { tasks: {}, amount: 0 },
  );

  Object.keys(data.tasks).forEach(key => {
    data.tasks[key].sort(
      (a, b) => a.ItemSequence - b.ItemSequence || a.TaskOrder - b.TaskOrder || !!a.Exceptions > !!b.Exceptions,
    );
  });

  return data;
};

export const prepareSaveTaskParams = ({
  actualFlow,
  actualQty,
  actualTemperature,
  feedbackResponse,
  message,
  id,
  requiresAttention,
  ruleType,
  verificationResponse,
  partsRequireAttention,
  partResolution,
  partIssueEstimationDto,
  assignedTaskParts,
  assignedTaskPartGroups,
  reportNotes,
}) => {
  const ruleTypeParams = {
    RequiresAttention: {
      id,
      requiresAttention,
      message,
      partsRequireAttention,
      partResolution,
      partIssueEstimationDto,
      assignedTaskParts,
      assignedTaskPartGroups,
      reportNotes,
    },
    [TASK_RULE_TYPES.VERIFICATION]: {
      id,
      verificationResponse: verificationResponse || undefined,
      requiresAttention: 0,
      message,
    },
    [TASK_RULE_TYPES.REPLACE_PARTS]: {
      id,
      verificationResponse: verificationResponse || undefined,
      requiresAttention: 0,
      message,
    },
    [TASK_RULE_TYPES.FEEDBACK]: { id, feedbackResponse, requiresAttention: 0, message },
    [TASK_RULE_TYPES.CHECK_FLOW_RATE]: { id, actualReading: actualFlow, requiresAttention: 0, message },
    [TASK_RULE_TYPES.CHECK_QUANTITY]: { id, actualReading: actualQty, requiresAttention: 0, message },
    [TASK_RULE_TYPES.CHECK_TEMPERATURE]: { id, actualReading: actualTemperature, requiresAttention: 0, message },
    [TASK_RULE_TYPES.PHOTO_CONFIRMATION]: { id, requiresAttention: 0, message },
    [TASK_RULE_TYPES.SCAN_CODE]: { id, requiresAttention: 0, message },
    ForceComplete: { taskId: id },
  };
  const model = requiresAttention ? ruleTypeParams.RequiresAttention : ruleTypeParams[ruleType];

  return { model };
};

export const findNextTaskId = (list, currentTaskId) => {
  let foundTask = {};
  const units = Object.keys(list.tasks);

  units.some(unit =>
    list.tasks[unit].some((item, index) => {
      if (item.Id !== currentTaskId) {
        return false;
      }

      foundTask = list.tasks[unit][index + 1];
      return true;
    }),
  );

  return foundTask && foundTask.Id;
};

export const prepareSaveTaskUrl = ({ ruleType, requiresAttention }) => {
  const ruleTypeUrl = {
    RequiresAttention: 'AssignedTaskActions/MarkTaskAsNeedsAttention/',
    [TASK_RULE_TYPES.VERIFICATION]: 'AssignedTaskActions/CompleteVerificationTask/',
    [TASK_RULE_TYPES.REPLACE_PARTS]: 'AssignedTaskActions/CompletePartReplacementTask/',
    [TASK_RULE_TYPES.FEEDBACK]: 'AssignedTaskActions/CompleteFeedbackTask/',
    [TASK_RULE_TYPES.CHECK_FLOW_RATE]: 'AssignedTaskActions/CompleteFlowRateTask/',
    [TASK_RULE_TYPES.CHECK_QUANTITY]: 'AssignedTaskActions/CompleteQuantityTask/',
    [TASK_RULE_TYPES.CHECK_TEMPERATURE]: 'AssignedTaskActions/CompleteTemperatureTask/',
    [TASK_RULE_TYPES.PHOTO_CONFIRMATION]: 'AssignedTaskActions/CompletePhotoConfirmationTask/',
    [TASK_RULE_TYPES.SCAN_CODE]: 'AssignedTaskActions/CompleteScanCodeTask/',
    ForceComplete: 'AssignedTaskActions/ForceCompleteTask/',
  };

  return requiresAttention ? ruleTypeUrl.RequiresAttention : ruleTypeUrl[ruleType];
};
