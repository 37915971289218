import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import theme from 'theme';

import { useAppSelector } from 'redux/reducers';
import { actions as tasksActions } from 'redux/tasks';
import { PartGroupType, PartIssueEstimationType, PartType, ReportPhotoType, RuleDataType, TaskType } from 'types/task';

import { Modal } from 'components/Modal';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';

import { MainInfo } from './MainInfo';
import { AddReportPhotosSection } from './AddReportPhotosSection';
import { AddReportNotesSection } from './AddReportNotesSection';
import { RequiresAttentionSection } from './RequiresAttentionSection';
import { PartsRequiringAttention } from './PartsRequiringAttention';
import { validate } from './validator';
import { getAssignedItems } from './helpers';

import * as S from './styled';

type PropsType = {
  task: TaskType;
};

export type FormValuesType = RuleDataType & {
  feedback: string | null;
  actualQty: number | null;
  actualTemperature: number | null;
  actualFlow: number | null;
  reportNotes: string;
  reportPhotos: Array<ReportPhotoType>;
  newPhoto: HTMLInputElement | null;
  taskIssue: string;
  partResolution: string;
  verificationResponse: boolean | null;
  selectedSection: 'photos' | 'requiresAttention' | 'notes' | '';
  isPartsRequireAttention: boolean;
  ruleType: string;
  photoConfirmation: File;
  signature: string | null;
  currentPhotos: number | null;
  scanCode: { file: File };
  partIssueEstimationDto: PartIssueEstimationType;
};

const TaskDetails = NiceModal.create((props: PropsType) => {
  const taskDetails = useAppSelector(state => state.tasks.taskDetails as TaskType);
  const task = isEmpty(taskDetails) ? props.task : taskDetails;
  const reportPhotos: ReportPhotoType[] = useAppSelector(state => state.tasks.reportPhotos);
  const parts: Array<PartType & { IsAssigned: boolean }> = useAppSelector(state => state.tasks.parts);
  const partGroups: Array<PartGroupType & { IsAssigned: boolean }> = useAppSelector(state => state.tasks.partGroups);

  const modal = useModal();
  const dispatch = useDispatch();
  const initialValues: FormValuesType = {
    feedback: task.RuleData.FeedbackResponse,
    actualQty: task.RuleData.ActualQty,
    actualTemperature: task.RuleData.ActualTemperature,
    actualFlow: task.RuleData.ActualFlow,
    verificationResponse: task.RuleData.VerificationResponse,
    signature: task.RuleData.SignatureResponse,
    currentPhotos: task.RuleData.CurrentPhotos,
    reportNotes: '',
    reportPhotos,
    newPhoto: null,
    taskIssue: '',
    partResolution: '',
    selectedSection: '',
    isPartsRequireAttention: false,
    ruleType: task.RuleType,
    photoConfirmation: {} as File,
    scanCode: {} as { file: File },
    partIssueEstimationDto: {
      assignedTaskId: task.Id,
      estimatedHours: undefined,
      estimatedMaterials: undefined,
      approvedBy: '',
      approvedByRole: undefined,
      approvalNotes: '',
      approvedOn: undefined,
    },
    ...task.RuleData,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate,
    onSubmit: values => {
      const {
        actualFlow,
        actualQty,
        actualTemperature,
        taskIssue,
        partIssueEstimationDto,
        partResolution,
        reportNotes,
        isPartsRequireAttention,
      } = values;
      const id = task.Id;
      const ruleType = task.RuleType;
      const assignedTaskParts = getAssignedItems(parts, id, 'PartID');
      const assignedTaskPartGroups = getAssignedItems(partGroups, id, 'PartGroupId');

      const payload = {
        id,
        ruleType,
        actualFlow,
        actualQty,
        reportNotes,
        actualTemperature,
        requiresAttention: values.selectedSection === 'requiresAttention',
        partsRequireAttention: isPartsRequireAttention,
        message: taskIssue,
        verificationResponse: values.verificationResponse,
        feedbackResponse: values.feedback,
        lastScanCode: values.scanCode.file,
        assignedTaskParts,
        assignedTaskPartGroups,
        partResolution,
        partIssueEstimationDto,
      };

      dispatch(tasksActions.saveTaskRequest(payload));
      formik.resetForm();
      modal.hide();
    },
  });

  const onRequestClose = () => {
    modal.hide();
    formik.resetForm();
  };

  useEffect(() => {
    dispatch(tasksActions.getReportPhotosListRequest(task.Id));
  }, [dispatch, task.Id]);

  return (
    <Modal isOpen={modal.visible} title="Task" onRequestClose={onRequestClose} titleColor={theme.primaryDark}>
      <S.Form onSubmit={formik.handleSubmit}>
        <S.Info>
          <S.UnitName>{task.UnitName}</S.UnitName>
          <S.ItemName>{task.OriginatingChecklistName}</S.ItemName>
        </S.Info>
        <S.ScrollContainer>
          <MainInfo task={task} formik={formik} />
          <S.Section>
            <AddReportNotesSection formik={formik} />
            <AddReportPhotosSection taskId={task.Id} formik={formik} />
            <RequiresAttentionSection formik={formik} />
            {formik.values.selectedSection === 'requiresAttention' && <PartsRequiringAttention formik={formik} />}
          </S.Section>
        </S.ScrollContainer>

        <ActionsContainer gapValue={14}>
          <ActionButton type="button" label="Cancel" onClick={modal.hide} />
          <ActionButton type="submit" isNext isGreenLabel label="Complete Task" />
        </ActionsContainer>
      </S.Form>
    </Modal>
  );
});

export default TaskDetails;
